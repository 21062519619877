<template>
  <div class="qiqb-table">
		<div class="qiqb-table-search">
		  <a-form :label-col="{span:7}" :wrapper-col="{span:17}">
		    <a-row>
		      <a-col :span="6" >
		      	<a-form-item label="发送时间">
		          <a-range-picker @change="change_time" />
		        </a-form-item>
		      </a-col>
		      <a-col :span="6" >
						<a-form-item label="消息类型">
		          <a-select v-model="queryParam.type" placeholder="请选择" :options="[{value:1,label:'系统消息'},{value:2,label:'关注消息'},{value:3,label:'更新消息'}]" />
		        </a-form-item>
					</a-col>
		      <a-col :span="6">
		        <a-form-item label="手机号码">
		          <a-input v-model="queryParam.title" placeholder="请输入" />
		        </a-form-item>
		      </a-col>
		      <a-col :span="6">
		        <div class="qiqb-table-search-btn">
		          <a-button type="primary" @click="more({ current: 1 })" >查询</a-button>
		          <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
		        </div>
		      </a-col>
		    </a-row>
		  </a-form>
		</div>
		
    <div class="qiqb-table-item">
      <div class="table-operator">
        <div class="table-operator-title">{{ this.$route.name }}</div>
				<a-button type="primary" @click="edit({id:0})">新建</a-button>
      </div> 
      <div class="table-item">
        <a-table :columns="columns" :data-source="item" :pagination="page" size="middle" @change="more">
					<span slot="uid" slot-scope="text">
						<span class="table-item-img">
							<img :src="text.img+'!120.120'" />
							{{text.name||text.username}}
						</span>
					</span>
					<span slot="type" slot-scope="text">
						<span v-if="text==1">系统消息</span> 
						<span v-if="text==2">关注消息</span>
						<span v-if="text==3">更新消息</span>
					</span>
          <span slot="action" slot-scope="text, record">				
            <a-popconfirm title="确认删除？" @confirm="del(record)">
              <a-icon slot="icon" type="question-circle-o" style="color: red"/>
              <a>删除</a>
            </a-popconfirm>
          </span>
        </a-table>
      </div>
    </div>
		
		<add ref="add" @ok="ok" />
  </div>
</template>

<script>
import add from "./modules/add"
import { config_msg, config_msg_add, config_msg_del } from "../../../api/index.js"

export default {
	components: {
	  add
	},
  methods: {
    async more(e) {
			this.queryParam.page = e.current
      let res = await config_msg(this.queryParam)
      if (res.code == 200) {
        this.item = res.data.data
        this.page.total = res.data.total
        this.page.current = res.data.current_page
      }
    },
    edit(record) {
      this.id = record.id
      this.$refs.add.init(record)
    },
    ok(e) {
			config_msg_add({id:this.id,item:e}).then((res)=>{
				if(res.code==200){
					this.$message.success(res.msg)
					this.$refs.add.cancel()
					this.more({ current: this.page.current })
				}else{
					this.$message.error(res.msg)
				}
			})   
    },
    del(e) {
			config_msg_del({id:e.id}).then((res)=>{
				if(res.code==200){
					this.$message.success(res.msg)
					this.more({ current: this.page.current })
				}else{
					this.$message.error(res.msg)
				}
			})
		},
		change_time(e,str){
			this.queryParam.time = str
		},
  },
  mounted() {
    this.more({ current: 1 });
  },
  data() {
    return {
      id: 0,
      queryParam: {},
      page: { pageSize: "10", total: 0, current: 0 },
      columns: [
				{ title:'编号', dataIndex:'id', width:80 },
				{ title:'接收用户', dataIndex:'uid', scopedSlots: { customRender: 'uid' }, width:180},
				{ title:"消息内容", dataIndex:"msg"},
				{ title:"消息类型", dataIndex:"type", scopedSlots: { customRender: 'type' }, width:100 },
				{ title:"更新时间", dataIndex: "update_time", width: 180 },
				{ title:"操作", width:80, scopedSlots: { customRender: 'action' }},
      ],
      item: []
    };
  }
};
</script>