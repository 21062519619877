<template>
  <a-modal :title="this.$route.name" :width="640" :visible="visible" @ok="ok"  @cancel="cancel" >
    <a-form :form="form" :label-col="{span:6}" :wrapper-col="{span:16}">
			<a-form-item label="隐藏字段" style="display:none;">
			</a-form-item>
			<a-form-item label="接收对象">
				<a-select v-decorator="['group',{rules:[{required:true,message:''}]}]" :options="[{value:0,label:'普通会员'},{value:1,label:'室内设计师'},{value:2,label:'项目经理'},{value:3,label:'工人师傅'},{value:4,label:'软装设计师'},{value:5,label:'效果图制作'}]" />
			</a-form-item>
			<a-form-item label="消息内容">
				<a-input v-decorator="['title',{rules:[{required:true,message:''}]}]" />
			</a-form-item>
			<a-form-item label="消息内容">
				<a-textarea v-decorator="['msg',{rules:[{required:true,message:''}]}]" />
			</a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import pick from "lodash.pick"
import QiqbImg from "../../../../components/QiqbImg.vue"

// 表单字段
const fields = ['group','msg','title']

export default {
	components:{
		QiqbImg
	},
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this)
    }
  },
  created() {
    fields.forEach((v) => this.form.getFieldDecorator(v))
  },
  methods: {
    init(e) {	
      this.form.setFieldsValue(pick(e, fields))
			this.$nextTick(() => {
				this.$refs.qiqb_img.init(e.img)
			})
      this.visible = true
    },
    cancel() {
			this.form.resetFields()
      this.visible = false
    },
    ok() {
      this.form.validateFields((errors, values) => {
        if (!errors){
					this.$emit("ok", values)
					this.form.resetFields()
				} 
      })
    },
		img_ok(e){
			this.form.setFieldsValue({img:e})
		}
  },
}
</script>
