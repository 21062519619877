<template>
	<div>
		<a-upload
	    name="file"
	    list-type="picture-card"
	    class="avatar-uploader"
	    :show-upload-list="false"
	    :action="api"
	    :before-upload="beforeUpload"
	    @change="handleChange"
	  >
			<div v-if="imageUrl" class="imgs">
				<img :src="imageUrl" alt="avatar" /><span>查看</span>
			</div>
	    <div v-if="!imageUrl">
	      <a-icon type="plus" />
	    </div>
	  </a-upload>
	</div>
</template>

<script>

	
export default {
	data(){
		return {
			api : process.env.VUE_APP_IMG_API,
			imageUrl:''
		}
	},
	methods:{
		init(e){
			this.imageUrl = e
		},
		handleChange(info) {
			if (info.file.status === 'uploading') {
				return;
			}
			if (info.file.status === 'done') {
				this.imageUrl = info.file.response.data
				this.$emit("ok",this.imageUrl);
			}		
		},
		beforeUpload(file) {
			const isLt1M = file.size / 1024 / 1024 < 5;
			if (!isLt1M) {
				this.$message.error('上传图片不能大于1M!');
			}
			return isLt1M;
		}
	}
}
</script>

<style>
.avatar-uploader img { max-width:200px;}
</style>
